<template>
  <div class="listtabs">
    <div class="list-left">
      <p class="headertitle">常见问题</p>
      <ul class="left-ul">
        <li
          class="left-li"
          :class="{ active: index == liid }"
          v-for="(item, index) in Listleft"
          :key="index"
          @click="toggle(index)"
        >
          {{ item }}
        </li>
      </ul>
    </div>
    <div class="list-right">
      <ul class="right-ul">
        <li
          class="right-li"
          v-for="(item, index) in Listright"
          :key="index"
          v-show="index == liid"
        >
          <p class="li-header">{{ item }}</p>
          <div v-for="item in 5" :key="item">
            <p>1. 发票的类型有哪些？</p>
            <p>
              目前可以开具电子增值税普通发票(简称“电子发票”)、纸质增值税普通发票(简称“纸质发票”)、以及增值税专用发票(简称“专用发票”)。
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: ["Listleft", "Listright"],
  data() {
    return {
      liid: 0,
    };
  },
  methods: {
    toggle(liid) {
      this.liid = liid;
    },
  },
};
</script>
<style lang="less" scoped>
.listtabs {
  width: 1200px;
  margin: 0 auto;
  position: relative;
  .list-left {
    position: absolute;
    width: 220px;
    border: 1px solid rgba(237, 237, 237, 1);
    background-color: #ffffff;
    text-align: left;
    .headertitle {
      margin-top: 10px;
      padding: 0 0 0 45px;
      width: 100%;
      height: 50px;
      line-height: 50px;
      font-size: 18px;
      font-weight: 700;
    }
    .left-ul {
      padding: 0 0 20px 0;
      .left-li {
        padding: 0 0 0 40px;
        width: 100%;
        height: 40px;
        line-height: 40px;
        font-size: 13px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        border-left: 5px solid #ffffff;
      }
      :hover {
        color: rgba(26, 188, 156, 1);
      }
      li.active {
        border-left: 5px solid rgba(26, 188, 156, 1);
        color: #1abc9c;
      }
    }
  }
  .list-right {
    position: absolute;
    width: 960px;
    left: 240px;
    height: 923px;
    border: 1px solid rgba(237, 237, 237, 1);
    background-color: #ffffff;
    .right-ul {
      .right-li {
        padding: 20px 40px;
        width: 100%;
        .li-header {
          padding: 30px 10px;
          border-bottom: 1px solid rgba(237, 237, 237, 1);
          font-size: 20px;
          font-weight: 400;
        }
        div {
          padding: 30px 0 0 0;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          color: #999999;
          :nth-child(1) {
            font-weight: 700;
          }
        }
      }
    }
  }
}
</style>