import { render, staticRenderFns } from "./Listltabs.vue?vue&type=template&id=6241d76b&scoped=true&"
import script from "./Listltabs.vue?vue&type=script&lang=js&"
export * from "./Listltabs.vue?vue&type=script&lang=js&"
import style0 from "./Listltabs.vue?vue&type=style&index=0&id=6241d76b&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6241d76b",
  null
  
)

export default component.exports